import React, { useState } from 'react';
import Model3dPart from '../../../ThreeDViewer/Model3d';
import '../../../ThreeDViewer/model3d.css';
import Play from '../../../img/playred.svg';
import axios from 'axios';
import JSZip from 'jszip';
import IframeViewer from '../../../ThreeDViewer/IframeViewer';
const ThreeDfilesViewer = (props) => {
  const [loadViewer, setLoadViewer] = useState(false);
  const [ZipFileLoader, setZipFileLoader] = useState(false);
  const { zipFileUrl, files } = props;
  const [zipFile, setZipFile] = useState(null);
  const show_new_viewer = process.env.REACT_APP_NEW_VIEWER;
  const unzipFile = async () => {
    props.setActiveModel(props.index);
    if (zipFileUrl) {
      try {
        setZipFileLoader(true);
        const zipData = await axios.get(zipFileUrl, {
          responseType: 'blob',
        });
        if (zipData.status === 200) {
          const zip = new JSZip();
          const unzippedFiles = await zip.loadAsync(zipData.data);

          // Create an array to store URLs for the unzipped files
          const fileUrls = [];
          // Iterate through the unzipped files and process them
          for (const [relativePath, file] of Object.entries(
            unzippedFiles.files
          )) {
            const fileData = await file.async('blob');
            // Create a URL for the Blob data
            const fileURL = URL.createObjectURL(fileData);
            // Store the URL in the object using the number extracted from the relative path as the key
            const match = relativePath.match(/(\d+)_/);
            if (match) {
              let number = parseInt(match[1]);
              fileUrls[number] = {
                upper: fileUrls[number]?.upper ?? {},
                lower: fileUrls[number]?.lower ?? {},
              };
              if (relativePath.toLowerCase().endsWith('.mtl')) {
                if (relativePath.toLowerCase().includes('upper')) {
                  fileUrls[number]['upper']['mtl'] = fileURL;
                } else if (relativePath.toLowerCase().includes('lower')) {
                  fileUrls[number]['lower']['mtl'] = fileURL;
                }
              } else if (relativePath.toLowerCase().endsWith('.obj')) {
                if (relativePath.toLowerCase().includes('upper')) {
                  fileUrls[number]['upper']['obj'] = fileURL;
                } else if (relativePath.toLowerCase().includes('lower')) {
                  fileUrls[number]['lower']['obj'] = fileURL;
                }
              }
            }
          }
          setZipFile(fileUrls);
        }
      } catch (error) {
        console.error('Error while unzipping 3d files:', error);
      } finally {
        setZipFileLoader(false);
        setLoadViewer(true);
      }
    } else {
      setLoadViewer(true);
      setZipFile(files);
    }
  };

  if (ZipFileLoader) {
    return (
      <div className="loading-container">
        <div className="loading"></div>
      </div>
    );
  }

  return (
    <div className="model3d">
      {loadViewer && props.isActive ? (
        !show_new_viewer ?
        <Model3dPart models={zipFile} bgColor={props.bgColor} /> :
        <div style={{width: '50%', margin: 'auto'}}>
          <IframeViewer planId={props.planId} />
        </div>
      ) : (
        <div className="load-model-btn">
          <a onClick={unzipFile}>
            <img src={Play} style={{ width: 100, height: 100 }} />
          </a>
        </div>
      )}
    </div>
  );
};

export default ThreeDfilesViewer;
