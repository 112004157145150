import {
  Button as PolarisButton,
} from "@shopify/polaris";
import { Box, CircularProgress, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "../axios";
import axiosAws from "axios";
import ProcedureHeader from "./ProcedureHeader";
import Cookies from "js-cookie";
import Save from "./Save";
import ScreeningPhotoUpload from "./ScreeningPhotoUpload";
import AlertDeleteDialog from "./Popup/alert-delete-dialog.component";
import { adminActions, caseRecordTypes, IssueTypes, ReviewStatus } from "../util/constants";
import DisapprovalModal from "./DisapprovalModal";
import ApprovalActions from "./ApprovalActions";

const useStyles = makeStyles(() => {
  return {
    wrapper: {
      padding: '2rem',
      boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    },
    uploadBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    float: {
      float: 'right',
    },
    input: {
      padding: '0.5rem 1.2rem',
      border: '1px lightgray solid',
      borderRadius: '5px',
      margin: '15px 0',
    },
    mediaWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: '2rem',
      marginBottom: '1rem',
    }
  };
});

const AppointmentAttachment = ({
  handleTabsExpand,
  appointmentId,
  appointmentNumber,
  openCloseTabs,
  index,
  appointmentType,
  userId,
  scrollToExpandedTab,
  updateReviewStatus,
  delayedActionJob,
}) => {
  const classes = useStyles();
  const [attachment, setAttachment] = useState(null);
  const [attachmentIcon, setAttachmentIcon] = useState(null);
  const [hasDelayedAction, setHasDelayedAction] = useState(false);
  const [success, setSuccess] = useState(false);
  const [popupActive, setPopupActive] = useState(false);
  const [errorFilesUpload, setErrorFilesUpload] = useState('');
  const [addFilesKey, setAddFilesKey] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [callHandleSave, setCallHandleSave] = useState(0);
  const [imageKey, setImageKey] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [photoId, setPhotoId] = useState(null);
  const [reviewData, setReviewData] = useState({
    comment: '',
    commentError: '',
    loading: false,
    modalOpened: false,
    status: ''
  });
  const [issues, setIssues] = useState([]);
  const [options, setOptions] = useState([]);
  const [fileImages, setFileImages] = useState([]);
  const [images, setImages] = useState([]);
  const [imageKeys, setImageKeys] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [groupTeeth, setGroupTeeth] = useState(Array(1).fill([
    { num: 18, attachment: false },
    { num: 17, attachment: false },
    { num: 16, attachment: false },
    { num: 15, attachment: false },
    { num: 14, attachment: false },
    { num: 13, attachment: false },
    { num: 12, attachment: false },
    { num: 11, attachment: false },
    { num: 21, attachment: false },
    { num: 22, attachment: false },
    { num: 23, attachment: false },
    { num: 24, attachment: false },
    { num: 25, attachment: false },
    { num: 26, attachment: false },
    { num: 27, attachment: false },
    { num: 28, attachment: false },
    { num: 48, attachment: false },
    { num: 47, attachment: false },
    { num: 46, attachment: false },
    { num: 45, attachment: false },
    { num: 44, attachment: false },
    { num: 43, attachment: false },
    { num: 42, attachment: false },
    { num: 41, attachment: false },
    { num: 31, attachment: false },
    { num: 32, attachment: false },
    { num: 33, attachment: false },
    { num: 34, attachment: false },
    { num: 35, attachment: false },
    { num: 36, attachment: false },
    { num: 37, attachment: false },
    { num: 38, attachment: false },
  ]));
  const [sheets, setSheets] = useState([
    {
      notes: '',
    },
  ]);

  const handleDataFetched = () => {
    setPopupActive(true);
    axios.get(`admin/v2/users/case-record-attachment/${appointmentId}/show?procedure=${appointmentType?.procedure}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then(res => {
      if (res.data.success) {
        setHasDelayedAction(res.data.has_delayed_action);
        if(!res.data.data.review_status){
          setReviewData(prev => ({
            ...prev,
            disabled: false,
          }));
        }
        setAttachment(res.data.data);
        setAttachmentIcon(res.data.isAllProvided);
        let files = res.data.data?.files;
        if(files?.length) {
          let arr = [];
          files.forEach(file => {
            arr.push({id: file?.name ? file.name : file.id, url: file.file_url});
          });
          setPhotos(arr);
        }
      } else {
        setAttachmentIcon(0);
      }
    }).catch(err => {
      console.log('fetch error', err);
    }).finally(() => {
      setPopupActive(false);
      setLoading(false);
    })
  };

  const handleChangeModal = () => {
    setReviewData(prev => ({
      ...prev,
      modalOpened: false,
    }));
    setIssues([]);
  }

  const handleChangeComment = (newValue) => {
    setReviewData(prev => ({
      ...prev,
      comment: newValue,
    }));
  }

  const handleChangeReview = (status) => {
    if(status == ReviewStatus.approved){
      handleReviewStatus(status);
    } else {
      setReviewData(prev => ({
        ...prev,
        modalOpened: true,
        status,
      }));
    }
  }

  const handleReviewStatus = async (status) => {
    if (status != ReviewStatus.approved && !issues.length && !reviewData.comment) {
      setReviewData(prev => ({
        ...prev,
        commentError: 'Please enter a comment',
      }));
    } else {
      let data = {
        review_status: status,
        appointment_id: appointmentId,
        procedure: appointmentType?.procedure,
        record_id: attachment?.id,
        record_type: caseRecordTypes.UserCaseRecordAttachment,
      };
      if(status != ReviewStatus.approved){
        let comment = `
          Appointment ${appointmentNumber} - ${appointmentType?.procedure}<br/>
          Status: Photos ${status}
        `;
        if (options.length && !issues.length) {
          return;
        }
        if(issues.length) {
          comment += '<br/>Attachment issues: ';
          comment += issues.map(issue => issue.label).join(', ');
        }
        let teeth = groupTeeth[0].filter(tooth => tooth.attachment);
        if(teeth.length) {
          comment += `<br/>Affected teeth: ${teeth.map(tooth => tooth.num).join(', ')}`;
        }
        if(sheets[0].notes) {
          comment += `<br/>Affected teeth notes: ${sheets[0].notes}`;
        }
        if(reviewData.comment) {
          comment += `<br/>Notes: ${reviewData.comment}`;
        }
        data = {
          ...data,
          comment,
          images: imageKeys,
          issues: issues.map(issue => issue.id),
        };
      }
      let body = {
        action: adminActions.caseRecordsStatus,
        data,
      }
      await updateReviewStatus(body, 'Status Updated', `${caseRecordTypes.UserCaseRecordAttachment}-${attachment?.id}`);
      setReviewData(prev => ({
        ...prev,
        disabled: false,
        loading: false,
        modalOpened: false,
      }));
    }
  };

  const uploadConfig = (progressEvent, name) => {
    if (progressEvent.loaded === progressEvent.total) {
      delete uploadProgress[name];
      setUploadProgress(uploadProgress);
    } else {
      const array = {
        ...uploadProgress,
        [name]: Math.round((progressEvent.loaded * 100) / progressEvent.total),
      };
      setUploadProgress(array);
    }
  };

  const handleUploadError = (err) => {
    setLoading(false);
    setErrorFilesUpload('Upload failed. Please try again.');
    setImageKey(null);
    console.log(err);
  };

  const handleImages = (e, id, fileName = null) => {
    setErrorFilesUpload('');
    setLoading(true);
    const file = e.target.files[0];
    let imageType = file.type;
    const form_data = new FormData();
    form_data.append("content_type", imageType);
    form_data.append("file_name", file.name);
    axios.post("/admin/v1/images/s3", form_data, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((res) => {
      if(imageKey){
        setImageKey(null);
      }
      setAddFilesKey([
        {
          id,
          file_type: imageType,
          file: res.data.key,
          name: fileName,
        },
      ]);
      const url2 = res.data.url;
      axiosAws.put(url2, file, {
        headers: {
          "x-amz-acl": "public-read-write",
          "Content-Type": imageType,
        },
        onUploadProgress: (event) => uploadConfig(event, id),
      }).then((res) => {
        setCallHandleSave(1);
      }).catch((err) => {
        handleUploadError(err, id);
      });
    }).catch((err) => {
      handleUploadError(err, id);
    })
  };

  const handleAddFiles = () => {
    setAttachment(prev => ({
      ...prev,
      files: [...prev.files, {}],
    }));
  };

  const handleClickOpen = (id) => {
    setPhotoId(id);
    setOpen(true);
  };

  const handleDelete = () => {
    setLoading(true);
    axios.delete(`admin/v2/case-record-attachment/delete/${photoId}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((res) => {
      handleDataFetched();
    }).catch((err) => {
      setErrorFilesUpload("Delete failed. Please try again.");
      console.log(err);
    }).finally(() => {
      setLoading(false);
    });
  }

  const handleSave = async () => {
    setErrorFilesUpload('');
    let body = {
      procedure: appointmentType.procedure,
      user_scan_info_id: appointmentId,
    };
    if(addFilesKey.length){
      body.files = addFilesKey;
    }
    try {
      const response = await axios.post(`/admin/v2/users/${userId}/case-record-attachment`, body, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      });
      if (response.status === 200) {
        if (addFilesKey.length > 0) {
          setAddFilesKey([]);
        }
        handleDataFetched();
      }
    } catch (error) {
      setSuccess(false);
    }
  };

  useEffect(() => {
    handleDataFetched();
  }, []);

  useEffect(() => {
    if(delayedActionJob.refetchedRecord === `${caseRecordTypes.UserCaseRecordAttachment}-${attachment?.id}`) {
      handleDataFetched();
    }
  }, [delayedActionJob]);

  useEffect(() => {
    if (callHandleSave === 1) {
      handleSave();
      setCallHandleSave(0);
    }
  }, [callHandleSave]);

  useEffect(() => {
    scrollToExpandedTab(index, 'attachment');
  }, [openCloseTabs]);

  return (
    <ProcedureHeader
      handleTabsExpand={handleTabsExpand}
      type='attachment'
      headerTitle={<p><span className="procedure-header-title">Photos:</span> <span className='header-title-gray'>{appointmentType?.procedure}</span></p>}
      index={index}
      iconCheck={attachmentIcon}
    >
      {openCloseTabs[index]?.attachment && (
        <Box className={`${classes.wrapper} attachment-${index}`}>
          {popupActive ?
            <div style={{ textAlign: 'center' }}>
              <CircularProgress color='primary' />
            </div> :
            <>
              <div style={{ textAlign: "right", marginTop: "1rem" }}>
                <PolarisButton
                  onClick={handleAddFiles}
                >
                  Add another file
                </PolarisButton>
              </div>
              <div className={classes.mediaWrapper}>
                <ScreeningPhotoUpload
                  disabled={loading}
                  value={attachment?.center_with_aligners_url}
                  fileType={attachment?.center_with_aligners_type}
                  handleImages={handleImages}
                  id='center_with_aligners'
                  title='Frontal with Aligners'
                  fileName='center_with_aligners'
                  uploadProgress={uploadProgress?.center_with_aligners}
                  handleDelete={() => handleClickOpen(attachment?.center_with_aligners_id)}
                  deleteDisabled={loading || !attachment?.center_with_aligners_id}
                  imagesArray={photos}
                  identifier={`attachment-${appointmentType?.procedure}`}
                />
                <ScreeningPhotoUpload
                  disabled={loading}
                  value={attachment?.left_with_aligners_url}
                  fileType={attachment?.left_with_aligners_type}
                  handleImages={handleImages}
                  id='left_with_aligners'
                  title='Left Lateral with Aligners'
                  fileName='left_with_aligners'
                  uploadProgress={uploadProgress?.left_with_aligners}
                  handleDelete={() => handleClickOpen(attachment?.left_with_aligners_id)}
                  deleteDisabled={loading || !attachment?.left_with_aligners_id}
                  imagesArray={photos}
                  identifier={`attachment-${appointmentType?.procedure}`}
                />
                <ScreeningPhotoUpload
                  disabled={loading}
                  value={attachment?.right_with_aligners_url}
                  fileType={attachment?.right_with_aligners_type}
                  handleImages={handleImages}
                  id='right_with_aligners'
                  title='Right Lateral with Aligners'
                  fileName='right_with_aligners'
                  uploadProgress={uploadProgress?.right_with_aligners}
                  handleDelete={() => handleClickOpen(attachment?.right_with_aligners_id)}
                  deleteDisabled={loading || !attachment?.right_with_aligners_id}
                  imagesArray={photos}
                  identifier={`attachment-${appointmentType?.procedure}`}
                />
              </div>
              <div className={classes.mediaWrapper}>
                <ScreeningPhotoUpload
                  disabled={loading}
                  value={attachment?.center_url}
                  fileType={attachment?.center_type}
                  handleImages={handleImages}
                  id='center'
                  title='Frontal without Aligners'
                  fileName='center'
                  uploadProgress={uploadProgress?.center}
                  handleDelete={() => handleClickOpen(attachment?.center_id)}
                  deleteDisabled={loading || !attachment?.center_id}
                  imagesArray={photos}
                  identifier={`attachment-${appointmentType?.procedure}`}
                />
                <ScreeningPhotoUpload
                  disabled={loading}
                  value={attachment?.left_url}
                  fileType={attachment?.left_type}
                  handleImages={handleImages}
                  id='left'
                  title='Left Lateral without Aligners'
                  fileName='left'
                  uploadProgress={uploadProgress?.left}
                  handleDelete={() => handleClickOpen(attachment?.left_id)}
                  deleteDisabled={loading || !attachment?.left_id}
                  imagesArray={photos}
                  identifier={`attachment-${appointmentType?.procedure}`}
                />
                <ScreeningPhotoUpload
                  disabled={loading}
                  value={attachment?.right_url}
                  fileType={attachment?.right_type}
                  handleImages={handleImages}
                  id='right'
                  title='Right Lateral without Aligners'
                  fileName='right'
                  uploadProgress={uploadProgress?.right}
                  handleDelete={() => handleClickOpen(attachment?.right_id)}
                  deleteDisabled={loading || !attachment?.right_id}
                  imagesArray={photos}
                  identifier={`attachment-${appointmentType?.procedure}`}
                />
              </div>
              <div className={classes.mediaWrapper}>
                {attachment?.files?.filter(file => !file.name).map((file, key) => {
                  return (
                    <ScreeningPhotoUpload
                      key={key}
                      disabled={loading}
                      value={file?.file_url ? file?.file_url : 'https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304'}
                      fileType={file?.file_type}
                      handleImages={handleImages}
                      id={file?.id ? file?.id : 0}
                      title=''
                      uploadProgress={uploadProgress[`image_${file?.id}`]}
                      handleDelete={() => handleClickOpen(file?.id)}
                      deleteDisabled={loading || !file?.id}
                      imagesArray={photos}
                      identifier={`attachment-${appointmentType?.procedure}`}
                    />
                  )
                })}
              </div>
              <br />
              <ApprovalActions
                disabled={hasDelayedAction || delayedActionJob.disabledRecord == `${caseRecordTypes.UserCaseRecordAttachment}-${attachment?.id}` || attachmentIcon == 0 || reviewData.disabled || attachment?.review_status != null}
                reviewStatus={attachment?.review_status}
                reviewer={attachment?.reviewer}
                reviewedAt={attachment?.reviewed_at}
                handleChangeReview={handleChangeReview}
              />
              <Save uploadedBy={attachmentIcon != 0 ? attachment?.owner_name : ''} handleSave={handleSave} success={success} errorMessage={errorFilesUpload} />
            </>
          }
        </Box>
      )}
      {reviewData.modalOpened && (
        <DisapprovalModal
          activeModal={reviewData.modalOpened}
          handleChangeModal={handleChangeModal}
          title={reviewData.status === ReviewStatus.approvedWithIssues ? 'Approve With Issues' : 'Disapprove Attachments Photos'}
          dropDownTitle='Attachments Photos Issues'
          handleSubmitDisapprove={() => handleReviewStatus(reviewData.status)}
          loading={reviewData.loading}
          commentValue={reviewData.comment}
          handleChangeComment={handleChangeComment}
          commentError={reviewData.commentError}
          setFilesImage={setFileImages}
          filesImage={fileImages}
          images={images}
          setImages={setImages}
          imagesKey={imageKeys}
          setImageKey={setImageKeys}
          issueType={IssueTypes.attachmentsNotApproved}
          setIssues={setIssues}
          issues={issues}
          showIPRDiagram
          sheets={sheets}
          setSheets={setSheets}
          groupTeeth={groupTeeth}
          setGroupTeeth={setGroupTeeth}
          options={options}
          setOptions={setOptions}
        />
      )}
      <AlertDeleteDialog open={open} setOpen={setOpen} handleAction={handleDelete} />
    </ProcedureHeader>
  );
};

export default AppointmentAttachment;
  