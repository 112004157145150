import UserHeader from "./Header/UserHeader";
import YourSmilePlan from "./SmilePlan/YourSmilePlan";
import Screening from "./Screening/Screening";
import Photos from "./Photos/Photos";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "../../axios";
import Cookie from "js-cookie";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./TeleConsultation.css";
import BasmaLogo from "../../img/basmalogo.svg";
import { DisplayText, Image } from "@shopify/polaris";
import emptyIcon from "../../img/emptyList.svg";

function TeleConsultation() {
  const [activeModel, setActiveModel] = useState(-1);
  const [threedModel, setThreedModel] = useState([]);
  const [yourSmilePlanData, setYourSmilePlanData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState("SA");
  const [paymentOptions, setPaymentOptions] = useState([]);

  let { id } = useParams();

  useEffect(() => {
    setLoading(true);
    axios
      .get(`admin/v1/users/${id}/smile-plan`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        setYourSmilePlanData(result.data.data);
      })
      .catch((err) => console.log("errors=", err))
      .finally(() => setLoading(false));
  }, []);

  const [expanded, setExpanded] = useState(false);
  const [expanded1, setExpanded1] = useState(false);

  const handleChange1 = (panel) => (event, newExpanded1) => {
    setExpanded1(newExpanded1 ? panel : false);
  };
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const fetchPaymentOptions = async () => {
    try {
      const response = await axios.get(
        `admin/v1/telepricing?country=${country}&user_id=${id}`,
        {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        }
      );
      if (response.status === 200) {
        setPaymentOptions(response.data.body);
      }
    } catch (err) {
      console.log("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPaymentOptions();
  }, [country]);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading"></div>
      </div>
    );
  }
  if (yourSmilePlanData.length === 0 && !loading) {
    return (
      <div className="error-container">
        <div>
          <Image
            style={{ display: "inline-block", width: "50px" }}
            src={emptyIcon}
          ></Image>
        </div>
        <DisplayText size="small">No treatment plan found</DisplayText>
        <br />
      </div>
    );
  }

  return (
    <div className="teleConsultContainer">
      <UserHeader
        name={yourSmilePlanData[0].user.full_name}
        userId={id}
        setCountry={setCountry}
        country={country}
      />
      <Accordion
        expanded={expanded1 === "panel1"}
        onChange={handleChange1("panel1")}
        disableGutters
        elevation={0}
        square
        sx={{ borderBottom: "1px solid #e0e0e0" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <Typography
            variant="h4"
            sx={{ fontFamily: `"Eina03-Bold", sens-serif` }}
          >
            <b style={{ color: "#ff2d47" }}>Patient Dental Records </b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Screening />
          <Photos />
        </AccordionDetails>
      </Accordion>

      {yourSmilePlanData.map((smilePlan, index) => (
        <div key={index}>
          {smilePlan.status === "Pending" ? null : (
            <Accordion
              key={smilePlan.id}
              expanded={expanded === index}
              onChange={handleChange(index)}
              disableGutters
              elevation={0}
              square
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography
                  variant="h4"
                  sx={{ fontFamily: `"Eina03-Bold", sens-serif` }}
                >
                  <b style={{ color: "#ff2d47" }}>
                    {smilePlan.name} - {smilePlan.status}
                  </b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {yourSmilePlanData.length ? (
                  <YourSmilePlan
                    yourSmilePlanData={yourSmilePlanData}
                    loading={loading}
                    setThreedModel={setThreedModel}
                    name={smilePlan.name}
                    status={smilePlan.status}
                    treatment_length={smilePlan.treatment_length}
                    ipr_url={smilePlan.ipr_url}
                    ipr_is_active={smilePlan.ipr_is_active}
                    guide_url={smilePlan.guide_url}
                    files={smilePlan.three_dimension_smile_url}
                    planId={smilePlan.id}
                    zipFileUrl={smilePlan.three_dimension_archive_url}
                    bgColor={0xf5f5f5}
                    setActiveModel={setActiveModel}
                    isActive={activeModel === index}
                    index={index}
                    preferred={smilePlan.preferred}
                    planType={smilePlan.plan_type}
                    paymentOptions={paymentOptions}
                    procedures={smilePlan.procedures}
                  />
                ) : null}
              </AccordionDetails>
            </Accordion>
          )}
        </div>
      ))}
      <div className="basmalogo">
        <img src={BasmaLogo} alt="Basma Logo" />
      </div>
    </div>
  );
}

export default TeleConsultation;
